import OtherLogo from "../../assets/icon-mp.png";
import OzonLogo from "../../assets/icon-ozon.png";
import WBLogo from "../../assets/icon-wb.png";

const ProductsList = (props) => {
  return (
    <div className="recipe-links-list">
      {props.products.map((item) => (
        <a href={item.url} target="_blank" rel="noreferrer" key={item.url}>
          <img
            src={
              item.url.indexOf('ozon') !== -1
                ? OzonLogo
                : item.url.indexOf('wildberries') !== -1
                ? WBLogo
                : OtherLogo
            }
            alt="Маркетплейс"
          />
          {item.text ?? item.title}
        </a>
      ))}
    </div>
  )
}

export default ProductsList
