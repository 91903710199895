import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Account from "./components/pages/Account";
import Blog from "./components/pages/Blog";
import Favorites from "./components/pages/Favorites";
import ForgotPassword from "./components/pages/ForgotPassword";
import Invite from "./components/pages/Invite";
import Login from "./components/pages/Login";
import Message from "./components/UI/Message";
import MessageFullwidth from "./components/UI/MessageFullwidth";
import Notification from "./components/UI/Notification";
import PaymentProblem from "./components/pages/PaymentProblem";
import Products from "./components/pages/Products";
import Ration from "./components/pages/Ration";
import RationList from "./components/pages/RationList";
import Recipe from "./components/pages/Recipe";
import Recipes from "./components/pages/Recipes";
import RecipesList from "./components/pages/RecipesList";
import Register from "./components/pages/Register";
import SearchResults from "./components/pages/SearchResults";
import ShopList from "./components/pages/ShopList";
import Subscribe from "./components/pages/Subscribe";
import TabBar from "./components/UI/TabBar";
import UserHeader from "./components/UI/UserHeader";
import WWPayment from "./components/pages/WWPayment";
import { useQuery } from "@tanstack/react-query";
import { Fragment, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { ToastContainer } from "react-toastify";
import { fetchUserData } from "./components/utils/http";
import { API_REFRESH } from "./config/main";
import { isObjEqual } from "./helpers";

import {
  Link,
  Navigate,
  Route,
  Routes,
  useLocation,
  useSearchParams,
} from 'react-router-dom'
import {
  checkLocalLogin,
  processLogout,
  setUserData,
} from './store/auth-actions'

function App() {
  const isLogged = useSelector((state) => state.auth.isLogged)
  const user = useSelector((state) => state.auth.user)
  const token = useSelector((state) => state.auth.token)

  const recipeComponentRef = useRef()
  const dispatch = useDispatch()
  const location = useLocation()

  // const notification = {
  //   message: 'Розырышь <b>начался</b>',
  //   link_url: '/subscribe/',
  //   link_text: 'Продлить тариф',
  // }

  const payload = user ? { user: user, user_id: user.id, token: token } : null

  const { data, isPending, isError, error } = useQuery({
    queryKey: ['userdata'],
    queryFn: ({ signal }) => fetchUserData({ signal, payload }),
    refetchInterval: API_REFRESH,
    enabled: isLogged && user ? true : false,
  })

  // Set utm cookies
  const [searchParams, setSearchParams] = useSearchParams()
  const utm_source = searchParams.get('utm_source')
  const utm_medium = searchParams.get('utm_medium')
  const utm_campaign = searchParams.get('utm_campaign')
  const utm_content = searchParams.get('utm_content')
  const u = searchParams.get('u')

  if (utm_source) {
    document.cookie = 'utm_source=' + utm_source
  }
  if (utm_medium) {
    document.cookie = 'utm_medium=' + utm_medium
  }
  if (utm_campaign) {
    document.cookie = 'utm_campaign=' + utm_campaign
  }
  if (utm_content) {
    document.cookie = 'utm_content=' + utm_content
  }
  if (u) {
    document.cookie = 'u=' + u
  }

  // Print recipes utils
  const handlePrintRecipe = useReactToPrint({
    content: () => recipeComponentRef.current,
  })

  // Update user data every 15 seconds
  useEffect(() => {
    if (data) {
      if (!isObjEqual(data, payload?.user)) {
        console.log('User data changed. Update.')
        dispatch(setUserData(data))
      }
    }
  }, [dispatch, data, payload?.user])

  // Check localstorage for login details
  useEffect(() => {
    dispatch(checkLocalLogin())
  }, [dispatch])

  // Remove context menu
  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault()
    }

    document.addEventListener('contextmenu', handleContextMenu)

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu)
    }
  }, [])

  // Logout on sync error
  if (isError) {
    dispatch(processLogout())
    return
  }

  return isLogged ? (
    <Fragment>
      <UserHeader />
      {!user.active ? (
        <MessageFullwidth type="error">
          <p>
            <strong>Ваш тариф не оплачен.</strong> Для полного ступа к сервису
            вам необходимо{' '}
            <Link to="/subscribe/">выбрать и оплатить тариф</Link>.
          </p>
          {user.discount ? (
            <p className="text-success">
              Вам, как покупателю основной книги рецептов, доступна{' '}
              <strong>вечная скидка 50%</strong>.
            </p>
          ) : null}
        </MessageFullwidth>
      ) : null}
      {user.notification ? (
        <Notification notification={user.notification} />
      ) : null}
      {isError && <Message content={error.info.message} type="error" />}
      <Routes>
        <Route path="/" element={<Navigate to="/recipes" replace />} />
        <Route path="/recipes" element={<Recipes />} />
        <Route path="/recipes/:type" element={<RecipesList />} />
        <Route path="/recipes/:type/tag/:tag" element={<RecipesList />} />
        <Route
          path="/recipe/:id"
          element={
            <Recipe onPrint={handlePrintRecipe} ref={recipeComponentRef} />
          }
        />
        <Route path="/search/:query" element={<SearchResults />} />
        <Route path="/account" element={<Account />} />
        <Route path="/ration" element={<RationList />} />
        <Route path="/ration/:id" element={<Ration />} />
        <Route path="/shoplist" element={<ShopList />} />
        <Route path="/favorites" element={<Favorites />} />
        <Route path="/subscribe" element={<Subscribe />} />
        <Route path="/blog/" element={<Blog />} />
        <Route path="/products/" element={<Products />} />
        <Route path="/ww-payment" element={<WWPayment />} />
        <Route path="/payment-problem/" element={<PaymentProblem />} />
        <Route path="/invite/" element={<Invite />} />
        <Route path="*" element={<Navigate to="/recipes" replace />} />
      </Routes>
      <TabBar />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </Fragment>
  ) : (
    <Routes key={location.pathname}>
      <Route path="/" element={<Navigate to="/login" replace />} />
      <Route path="/logout" element={<Navigate to="/" replace />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  )
}

export default App
