import PageContent from "../UI/PageWrapper";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RECIPES_COUNT, SITE_URL } from "../../config/main";
import { API_REFRESH, API_URL } from "../../config/main";
import { useBodyClass } from "../../helpers";

const Invite = () => {
  useBodyClass('page-invite')

  const [referralsData, setReferralsData] = useState(null)

  const user = useSelector((state) => state.auth.user)
  const token = useSelector((state) => state.auth.token)

  const [message, setMessage] = useState()
  const [isLoading, setIsLoading] = useState(true)

  // Fetch shoplist
  const fetchReferralsData = useCallback(async (payload) => {
    setMessage(null)
    setIsLoading(true)

    try {
      const response = await fetch(
        API_URL +
          `?act=getreferrals&user_id=${payload.user_id}&token=${payload.token}`
      )

      if (!response.ok) {
        throw new Error('Ошибка доступа к API.')
      }

      const data = await response.json()

      setIsLoading(false)

      if (data.type === 'error') {
        setMessage({ type: 'error', text: data.message })
      }

      if (data.type === 'success') {
        setReferralsData(data.data)
      }
    } catch (error) {
      setIsLoading(false)
      setMessage({ type: 'error', text: error.message })
    }
  }, [])

  // Use effects
  useEffect(() => {
    fetchReferralsData({
      user_id: user.id,
      token: token,
    })
  }, [fetchReferralsData, token, user.id])

  // Ref link copy
  const refLink = SITE_URL + '?u=' + user.id

  const copyLinkHandler = () => {
    const text =
      'Mi.Рецепты - ' +
      RECIPES_COUNT +
      ' авторских рецептов от дипломированного детского нутрициолога, генератор рационов и многое другое. Получи дополнительный бесплатный месяц подписки по моей ссылке: ' +
      refLink
    navigator.clipboard.writeText(text)

    toast.success('Ссылка скопирована в буфер обмена.')
  }

  return (
    <PageContent>
      <div className="page-title-wrapper">
        <h1 className="page-title">Бесплатная подписка</h1>
      </div>
      <div className="page-description">
        <h3>
          Приглашай подруг и получай бесплатные месяцы подписки вместе с ними!
        </h3>
        <p>
          Поделитесь вашей реферальной ссылкой с вашей подругой, и если она
          приобретет любой тариф в сервисе{' '}
          <strong>
            вы обе получите по одному дополнительному бесплатному месяцу
            подписки
          </strong>
          .{' '}
        </p>
        <p>
          <strong>Количество приглашений не ограничено</strong>, вы будете
          получать по одному дополнительному бесплатному месяцу за каждого
          пользователя.
        </p>
        <p>
          <h3>Ваша реферальная ссылка:</h3>
        </p>
        <p>
          <input type="text" readonly value={refLink} />
        </p>
        <p>
          <a className="button button-fixed" onClick={copyLinkHandler}>
            Скопировать ссылку
          </a>
        </p>
        <p>
          <h3>Как поделиться ссылкой:</h3>
        </p>
        <p>
          Нажмите на кнопку "Скопировать ссылку", затем вставьте ее вашей
          подруге в Telegram, Whatsup, ВКонтакте, Email или любой другой соц
          сети или мессенжере. Для этого, на смартфоне нажмите и удерживайте в
          поле ввода сообщения собеседнику и выберите пункт меню "Вставить", на
          компьютере вызовите контексное меню (правой кнопкой мыши) и выберите
          "Вставить".
        </p>
        <p>
          <h3>Статистика:</h3>
        </p>
        <p>
          <strong>Приглашено:</strong> {referralsData?.referrals_count}
        </p>
        <p>
          <strong>Оплатили подписку:</strong>{' '}
          {referralsData?.referrals_paid_count}
        </p>
      </div>
    </PageContent>
  )
}

export default Invite
