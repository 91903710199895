import ProductsList from "./ProductsList";
import { useState } from "react";
import { AiOutlineLink } from "react-icons/ai";

const Links = (props) => {
  const [isOpened, setIsOpened] = useState(false)

  const toggleLinksHandler = (e) => {
    setIsOpened((prevState) => {
      return !prevState
    })
  }

  return (
    <div className="recipe-links-wrapper">
      <div className="recipe-links-title" onClick={toggleLinksHandler}>
        <AiOutlineLink />
        {'  '} {!isOpened ? 'Показать' : 'Скрыть'} использованные продукты
      </div>
      {isOpened ? <ProductsList products={props.links} /> : ''}
    </div>
  )
}

export default Links
