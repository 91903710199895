import LoaderRecipeLine from "../loader/LoaderRecipeLine";
import Message from "../UI/Message";
import PageContent from "../UI/PageWrapper";
import ProductsList from "../recipe/ProductsList";
import RationItem from "../ration/RationItem";
import { useCallback, useEffect, useRef, useState } from "react";
import { IoAddCircleOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { API_URL } from "../../config/main";
import { useBodyClass } from "../../helpers";

const Products = () => {
  useBodyClass('page-products')

  const [message, setMessage] = useState()
  const [products, setProducts] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const user = useSelector((state) => state.auth.user)
  const token = useSelector((state) => state.auth.token)

  // Fetch rations list
  const fetchProducts = useCallback(async (payload) => {
    setMessage(null)
    setIsLoading(true)

    try {
      const response = await fetch(
        API_URL +
          `?act=getproducts&user_id=${payload.user_id}&token=${payload.token}`
      )

      if (!response.ok) {
        throw new Error('Ошибка доступа к API.')
      }

      const data = await response.json()

      setIsLoading(false)

      if (data.type === 'error') {
        setMessage({ type: 'error', text: data.message })
      }

      if (data.type === 'success') {
        setProducts(data.data)
      }
    } catch (error) {
      setIsLoading(false)
      setMessage({ type: 'error', text: error.message })
    }
  }, [])

  // Use effects
  useEffect(() => {
    fetchProducts({
      user_id: user.id,
      token: token,
    })
  }, [fetchProducts, token, user.id])

  if (!user.active) {
    return (
      <PageContent>
        <div className="page-title-wrapper">
          <h1 className="page-title">Список продуктов</h1>
        </div>
        <div className="page-description">
          Список продуктов используемых в рецептах со ссылками для покупки
          доступен только при активном тарифе.
        </div>
      </PageContent>
    )
  }

  return (
    <PageContent>
      <div className="page-title-wrapper">
        <h1 className="page-title">Список продуктов для ингредиентов</h1>
      </div>
      {message && <Message content={message.text} type={message.type} />}
      {isLoading && <LoaderRecipeLine />}
      {!isLoading && products && products.length > 0 && (
        <ProductsList products={products} />
      )}
    </PageContent>
  )
}

export default Products
